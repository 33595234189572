import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ActivityTypes } from 'botframework-schema';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { Resizable } from 're-resizable';
import { Skeleton } from 'antd';

import './index.less';

import {
  ActivityDirection,
  ActivityModel,
  ActivitySortDirection,
  ConversationModel,
  ConversationStatus,
  InboxDirection,
} from '../../../../../api';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { AlertTypes } from '../../../../constants';
import { activityApi } from '../../../../apis';
import { ActivityEventNames, RESIZABLE_COLUMN_CLASS_NAME, TriggerNames } from '../const';
import IbSpin from '../../../components/common/IbSpin';
import IbIcon from '../../../components/common/IbIcon';

import TransferToOperatorInitiated from './events/TransferToOperatorInitiated';
import KnowledgeBaseAnswerFound from './events/KnowledgeBaseAnswerFound';
import ExternalEventTrigger from './events/ExternalEventTrigger';
import TransitionTrigger from './events/TransitionTrigger';
import IntentRecognized from './events/IntentRecognized';
import MenuItemSelected from './events/MenuItemSelected';
import ScenarioStarted from './events/ScenarioStarted';
import TerminalTrigger from './events/TerminalTrigger';
import CommandTrigger from './events/CommandTrigger';
import ScriptExecuted from './events/ScriptExecuted';
import PromptAccepted from './events/PromptAccepted';
import MessageBubble from './MessageBubble';
import UnknownIntent from './events/UnknownIntent';
import ErrorOccured from './events/ErrorOccured';
import StartTrigger from './events/StartTrigger';
import MessageInfo from './MessageInfo';
import DialogInfo from './DialogInfo';
import ErrorInfo from './ErrorInfo';
import ScriptExecutionInfo from './ScriptExecutionInfo';
import SessionStatus from './SessionStatus';
import {
  ACTIVITIES_PER_REQUEST,
  DIALOG_MESSAGES_SCROLL_ID,
  EMPTY_CLASS_NAME,
  INFO_CLASS_NAME,
  MESSAGES_CLASS_NAME,
} from './const';

interface IDialogHistoryProps {
  conversation?: ConversationModel;
}

const DialogHistory: React.FC<IDialogHistoryProps> = ({ conversation }) => {
  const { t } = useTranslation();
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [selectedActivity, setSelectedActivity] = useState<ActivityModel>();
  const [activities, setActivities] = useState([] as ActivityModel[]);
  const [reference, setReference] = useState<ActivityModel>();
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingActivities, setLoadingActivities] = useState(false);

  const loadActivities = async (loadMore = true) => {
    if (!conversation || loadingActivities) return;

    loadMore ? setLoadingActivities(true) : setLoading(true);

    try {
      const sorting = ActivitySortDirection.CreatedOnDescending;
      const activitiesResponse = await activityApi.searchActivitiesByKeyset(
        undefined,
        undefined,
        undefined,
        conversation.id,
        undefined,
        undefined,
        undefined,
        false, // NOTE: не исключать внутренние сообщения между операторами
        sorting,
        ACTIVITIES_PER_REQUEST,
        loadMore ? reference?.id || undefined : undefined,
        loadMore ? reference?.createdOn || undefined : undefined
      );

      if (loadMore) {
        setActivities([...activities, ...(activitiesResponse.data.items ?? [])]);
      } else {
        setActivities(activitiesResponse.data.items ?? []);
      }
      setReference(activitiesResponse.data.reference);
      setHasMore(activitiesResponse.data.hasMore ?? false);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: t('Failed to load messages list'),
        error: e,
      });
    }
    setLoadingActivities(false);
    setLoading(false);
  };

  const onMessageSelect = (activity: ActivityModel) => {
    const newSelectedActivities = [selectedActivity].includes(activity) ? [] : [activity];
    const newActivity = newSelectedActivities.pop();
    setSelectedActivity(newActivity);
  };

  const getMenuItemSelected = (activity: ActivityModel) => {
    if (!activity.suggestedActions) return;

    const activityIndex = activities.indexOf(activity);
    const remainingActivities = activities.slice(0, activityIndex).reverse();
    const selectedActivity = remainingActivities.find(
      (item) => item.suggestedActions || item.name == ActivityEventNames.MenuItemSelected
    );
    if (selectedActivity?.name == ActivityEventNames.MenuItemSelected) {
      return selectedActivity;
    }

    const buttonSelected = remainingActivities.find(
      (item) =>
        item.suggestedActions || (item.type == ActivityTypes.Message && item.direction == ActivityDirection.Inbound)
    );
    return activity.suggestedActions?.some((i) => i == buttonSelected?.text) ? buttonSelected : undefined;
  };

  const getRecognizerResult = (activity: ActivityModel) => {
    const activityIndex = activities.indexOf(activity);
    const remainingActivities = activities.slice(0, activityIndex).reverse();
    const selectedActivity = remainingActivities.find(
      (item) =>
        item.text == activity.text ||
        (item.name == ActivityEventNames.OmegaRecognizer && item.value.Result?.text == activity.text)
    );
    return selectedActivity?.name == ActivityEventNames.OmegaRecognizer ? selectedActivity : undefined;
  };

  const getSigmaRecognizerResult = (activity: ActivityModel) => {
    const activityIndex = activities.indexOf(activity);
    const remainingActivities = activities.slice(0, activityIndex).reverse();
    return remainingActivities.find(
      (item) => item.name == ActivityEventNames.SigmaRecognizer && item.value.message?.text == activity.text
    );
  };

  const getScenarioInfo = (activity: ActivityModel) => {
    const activityIndex = activities.indexOf(activity);
    if (activityIndex < 1) return;
    const previousActivities = activities.slice(activityIndex + 1);
    const scenarioActivity = previousActivities.find(
      (item) =>
        item.name == ActivityEventNames.ScenarioStarted ||
        (item.type != ActivityTypes.Event && item.type != ActivityTypes.Trace)
    );
    if (scenarioActivity?.name == ActivityEventNames.ScenarioStarted) {
      return scenarioActivity;
    }
  };

  const getTriggerInfo = (activity: ActivityModel) => {
    const activityIndex = activities.indexOf(activity);
    if (activityIndex < 1) return;
    const previousActivities = activities.slice(activityIndex + 1);
    const triggerActivity = previousActivities.find(
      (item) =>
        item.name == ActivityEventNames.TriggerFired ||
        (item.type != ActivityTypes.Event && item.type != ActivityTypes.Trace)
    );
    if (triggerActivity?.name == ActivityEventNames.TriggerFired) {
      return triggerActivity;
    }
  };

  const renderTriggerEvent = (activity: ActivityModel) => (
    <>
      {activity.text == TriggerNames.OmegaIntent && <IntentRecognized name={activity.value.IntentName} />}
      {activity.text == TriggerNames.SigmaIntent && <KnowledgeBaseAnswerFound />}
      {activity.text == TriggerNames.MenuButton && <MenuItemSelected />}
      {activity.text == TriggerNames.UnknownIntent && <UnknownIntent />}
      {activity.text == TriggerNames.Transition && <TransitionTrigger />}
      {activity.text == TriggerNames.Terminal && <TerminalTrigger />}
      {activity.text == TriggerNames.ExternalEvent && <ExternalEventTrigger id={activity.value.externalEventId} />}
      {activity.text == TriggerNames.Start && <StartTrigger />}
      {activity.text == TriggerNames.Command && <CommandTrigger />}
    </>
  );

  const renderActivity = (activity: ActivityModel) => (
    <div key={activity.id ?? ''}>
      {activity.type == ActivityTypes.Message && (
        <MessageBubble
          activity={activity}
          direction={activity.direction}
          getMenuItemSelected={getMenuItemSelected}
          inboxDirection={activity.properties.direction ?? InboxDirection.Outbound}
          isSelected={activity.id == selectedActivity?.id}
          onSelect={onMessageSelect}
        />
      )}
      {activity.name == ActivityEventNames.TransferToOperatorInitiated && <TransferToOperatorInitiated />}
      {activity.name == ActivityEventNames.ScenarioStarted && <ScenarioStarted name={activity.text} />}
      {activity.name == ActivityEventNames.TriggerFired && renderTriggerEvent(activity)}
      {activity.name == ActivityEventNames.PromptAccepted && <PromptAccepted />}
      {activity.name == ActivityEventNames.ScriptExecuted && (
        <ScriptExecuted activity={activity} onSelect={onMessageSelect} />
      )}
      {activity.name == ActivityEventNames.ErrorOccured && (
        <ErrorOccured activity={activity} onSelect={onMessageSelect} />
      )}
    </div>
  );

  const renderInfo = () => {
    if (selectedActivity?.name === ActivityEventNames.ErrorOccured) {
      return <ErrorInfo activity={selectedActivity} />;
    }

    if (selectedActivity?.name === ActivityEventNames.ScriptExecuted) {
      return <ScriptExecutionInfo activity={selectedActivity} />;
    }

    if (selectedActivity) {
      return (
        <MessageInfo
          activity={selectedActivity}
          getRecognizerResult={getRecognizerResult}
          getScenarioInfo={getScenarioInfo}
          getSigmaRecognizerResult={getSigmaRecognizerResult}
          getTriggerInfo={getTriggerInfo}
        />
      );
    }

    if (conversation) {
      return <DialogInfo conversation={conversation} />;
    }
  };

  const loadData = () => {
    setSelectedActivity(undefined);
    loadActivities(false).finally();
  };
  useEffect(loadData, [conversation]);

  if (!conversation) {
    return (
      <div className={EMPTY_CLASS_NAME}>
        <IbIcon iconName="comments" size={64} />
        <div>
          {t('Select dialog for')}
          <br /> {t('more information')}
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className={EMPTY_CLASS_NAME}>
        <IbSpin />
      </div>
    );
  }

  return (
    <>
      <div className={MESSAGES_CLASS_NAME} id={DIALOG_MESSAGES_SCROLL_ID}>
        <InfiniteScroll
          inverse
          dataLength={activities.length}
          hasMore={hasMore}
          initialScrollY={0}
          loader={<Skeleton active />}
          next={loadActivities}
          scrollableTarget={DIALOG_MESSAGES_SCROLL_ID}
        >
          <>
            {conversation.status === ConversationStatus.Closed && (
              <SessionStatus isClosed timestamp={conversation.finishedOn} />
            )}
            {activities.map((a) => renderActivity(a))}
            {!hasMore && <SessionStatus timestamp={conversation.startedOn} />}
          </>
        </InfiniteScroll>
      </div>
      <Resizable className={RESIZABLE_COLUMN_CLASS_NAME} defaultSize={{ width: 386 }} enable={{ left: true }}>
        <div className={INFO_CLASS_NAME}>{renderInfo()}</div>
      </Resizable>
    </>
  );
};

export default DialogHistory;
